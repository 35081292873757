@import '../../../scss/base/variables';

/**
 * AutoModal
 */

.modal {
	$block: #{&};

	background: map-get($colours, 'overlay-dark');
	color: map-get($colours, 'copy');
	width: 100%;
	height: 100%;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: none;
	opacity: 0;

	justify-content: center;
	align-items: center;

	z-index: 100;
	transition: all .3s ease-in-out;

	&--active {
		opacity: 1;
	}

	&--transition {
		display: flex;
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;

		box-shadow: none;
	}

	&__content {
		background: map-get($colours, 'content');

		width: 100%;
		height: 100%;
		overflow: scroll;

		padding: 8rem 4rem 4rem;
		font-size: 1.6rem;
		line-height: 2.4rem;
		text-align: left;

		box-shadow: 0 0 12px 2px rgba(0,0,0,.12);
		position: relative;
	}

	&__buttons {
		margin-top: 3rem;
	}

	ul {
		list-style: none;
		margin: 0 0 0 4.5rem;
		padding: 0;

		li {
			margin-bottom: 2.5rem;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 13px;
				height: 13px;

				position: absolute;
				top: 8px;
				left: -40px;

				float: left;
				border-radius: 50%;
				background-color: map-get($colours, 'ocean');
			}
		}
	}

	@include breakpoint($mobileLarge) {

		ul {
			margin-left: 5.5rem;
		}
	}

	@include breakpoint($tablet) {

		padding: 10%;

		&__content {
			width: auto;
			//height: auto;
			padding: 8rem;
			//overflow: hidden;
		}

		label {
			position: static;
			width: auto;
			transform: none;
			text-align: left;
		}
	}

	@include breakpoint($tabletLarge) {

		&__content {
			font-size: 1.8rem;
		}
	}
}
