@import '../../../scss/base/variables';

/**
 * Loader
 */

@-webkit-keyframes load5 {

	0%,
	100% {
		box-shadow: 0em -2.6em 0em 0em map-get($colours, 'primary'), 1.8em -1.8em 0 0em rgba(0,47,58, 0.2), 2.5em 0em 0 0em rgba(0,47,58, 0.2), 1.75em 1.75em 0 0em rgba(0,47,58, 0.2), 0em 2.5em 0 0em rgba(0,47,58, 0.2), -1.8em 1.8em 0 0em rgba(0,47,58, 0.2), -2.6em 0em 0 0em rgba(0,47,58, 0.5), -1.8em -1.8em 0 0em rgba(0,47,58, 0.7);
	}

	12.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.7), 1.8em -1.8em 0 0em map-get($colours, 'primary'), 2.5em 0em 0 0em rgba(0,47,58, 0.2), 1.75em 1.75em 0 0em rgba(0,47,58, 0.2), 0em 2.5em 0 0em rgba(0,47,58, 0.2), -1.8em 1.8em 0 0em rgba(0,47,58, 0.2), -2.6em 0em 0 0em rgba(0,47,58, 0.2), -1.8em -1.8em 0 0em rgba(0,47,58, 0.5);
	}

	25% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.5), 1.8em -1.8em 0 0em rgba(0,47,58, 0.7), 2.5em 0em 0 0em map-get($colours, 'primary'), 1.75em 1.75em 0 0em rgba(0,47,58, 0.2), 0em 2.5em 0 0em rgba(0,47,58, 0.2), -1.8em 1.8em 0 0em rgba(0,47,58, 0.2), -2.6em 0em 0 0em rgba(0,47,58, 0.2), -1.8em -1.8em 0 0em rgba(0,47,58, 0.2);
	}

	37.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.2), 1.8em -1.8em 0 0em rgba(0,47,58, 0.5), 2.5em 0em 0 0em rgba(0,47,58, 0.7), 1.75em 1.75em 0 0em map-get($colours, 'primary'), 0em 2.5em 0 0em rgba(0,47,58, 0.2), -1.8em 1.8em 0 0em rgba(0,47,58, 0.2), -2.6em 0em 0 0em rgba(0,47,58, 0.2), -1.8em -1.8em 0 0em rgba(0,47,58, 0.2);
	}

	50% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.2), 1.8em -1.8em 0 0em rgba(0,47,58, 0.2), 2.5em 0em 0 0em rgba(0,47,58, 0.5), 1.75em 1.75em 0 0em rgba(0,47,58, 0.7), 0em 2.5em 0 0em map-get($colours, 'primary'), -1.8em 1.8em 0 0em rgba(0,47,58, 0.2), -2.6em 0em 0 0em rgba(0,47,58, 0.2), -1.8em -1.8em 0 0em rgba(0,47,58, 0.2);
	}

	62.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.2), 1.8em -1.8em 0 0em rgba(0,47,58, 0.2), 2.5em 0em 0 0em rgba(0,47,58, 0.2), 1.75em 1.75em 0 0em rgba(0,47,58, 0.5), 0em 2.5em 0 0em rgba(0,47,58, 0.7), -1.8em 1.8em 0 0em map-get($colours, 'primary'), -2.6em 0em 0 0em rgba(0,47,58, 0.2), -1.8em -1.8em 0 0em rgba(0,47,58, 0.2);
	}

	75% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.2), 1.8em -1.8em 0 0em rgba(0,47,58, 0.2), 2.5em 0em 0 0em rgba(0,47,58, 0.2), 1.75em 1.75em 0 0em rgba(0,47,58, 0.2), 0em 2.5em 0 0em rgba(0,47,58, 0.5), -1.8em 1.8em 0 0em rgba(0,47,58, 0.7), -2.6em 0em 0 0em map-get($colours, 'primary'), -1.8em -1.8em 0 0em rgba(0,47,58, 0.2);
	}

	87.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.2), 1.8em -1.8em 0 0em rgba(0,47,58, 0.2), 2.5em 0em 0 0em rgba(0,47,58, 0.2), 1.75em 1.75em 0 0em rgba(0,47,58, 0.2), 0em 2.5em 0 0em rgba(0,47,58, 0.2), -1.8em 1.8em 0 0em rgba(0,47,58, 0.5), -2.6em 0em 0 0em rgba(0,47,58, 0.7), -1.8em -1.8em 0 0em map-get($colours, 'primary');
	}
}

@keyframes load5 {

	0%,
	100% {
		box-shadow: 0em -2.6em 0em 0em map-get($colours, 'primary'), 1.8em -1.8em 0 0em rgba(0,47,58, 0.2), 2.5em 0em 0 0em rgba(0,47,58, 0.2), 1.75em 1.75em 0 0em rgba(0,47,58, 0.2), 0em 2.5em 0 0em rgba(0,47,58, 0.2), -1.8em 1.8em 0 0em rgba(0,47,58, 0.2), -2.6em 0em 0 0em rgba(0,47,58, 0.5), -1.8em -1.8em 0 0em rgba(0,47,58, 0.7);
	}

	12.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.7), 1.8em -1.8em 0 0em map-get($colours, 'primary'), 2.5em 0em 0 0em rgba(0,47,58, 0.2), 1.75em 1.75em 0 0em rgba(0,47,58, 0.2), 0em 2.5em 0 0em rgba(0,47,58, 0.2), -1.8em 1.8em 0 0em rgba(0,47,58, 0.2), -2.6em 0em 0 0em rgba(0,47,58, 0.2), -1.8em -1.8em 0 0em rgba(0,47,58, 0.5);
	}

	25% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.5), 1.8em -1.8em 0 0em rgba(0,47,58, 0.7), 2.5em 0em 0 0em map-get($colours, 'primary'), 1.75em 1.75em 0 0em rgba(0,47,58, 0.2), 0em 2.5em 0 0em rgba(0,47,58, 0.2), -1.8em 1.8em 0 0em rgba(0,47,58, 0.2), -2.6em 0em 0 0em rgba(0,47,58, 0.2), -1.8em -1.8em 0 0em rgba(0,47,58, 0.2);
	}

	37.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.2), 1.8em -1.8em 0 0em rgba(0,47,58, 0.5), 2.5em 0em 0 0em rgba(0,47,58, 0.7), 1.75em 1.75em 0 0em map-get($colours, 'primary'), 0em 2.5em 0 0em rgba(0,47,58, 0.2), -1.8em 1.8em 0 0em rgba(0,47,58, 0.2), -2.6em 0em 0 0em rgba(0,47,58, 0.2), -1.8em -1.8em 0 0em rgba(0,47,58, 0.2);
	}

	50% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.2), 1.8em -1.8em 0 0em rgba(0,47,58, 0.2), 2.5em 0em 0 0em rgba(0,47,58, 0.5), 1.75em 1.75em 0 0em rgba(0,47,58, 0.7), 0em 2.5em 0 0em map-get($colours, 'primary'), -1.8em 1.8em 0 0em rgba(0,47,58, 0.2), -2.6em 0em 0 0em rgba(0,47,58, 0.2), -1.8em -1.8em 0 0em rgba(0,47,58, 0.2);
	}

	62.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.2), 1.8em -1.8em 0 0em rgba(0,47,58, 0.2), 2.5em 0em 0 0em rgba(0,47,58, 0.2), 1.75em 1.75em 0 0em rgba(0,47,58, 0.5), 0em 2.5em 0 0em rgba(0,47,58, 0.7), -1.8em 1.8em 0 0em map-get($colours, 'primary'), -2.6em 0em 0 0em rgba(0,47,58, 0.2), -1.8em -1.8em 0 0em rgba(0,47,58, 0.2);
	}

	75% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.2), 1.8em -1.8em 0 0em rgba(0,47,58, 0.2), 2.5em 0em 0 0em rgba(0,47,58, 0.2), 1.75em 1.75em 0 0em rgba(0,47,58, 0.2), 0em 2.5em 0 0em rgba(0,47,58, 0.5), -1.8em 1.8em 0 0em rgba(0,47,58, 0.7), -2.6em 0em 0 0em map-get($colours, 'primary'), -1.8em -1.8em 0 0em rgba(0,47,58, 0.2);
	}

	87.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(0,47,58, 0.2), 1.8em -1.8em 0 0em rgba(0,47,58, 0.2), 2.5em 0em 0 0em rgba(0,47,58, 0.2), 1.75em 1.75em 0 0em rgba(0,47,58, 0.2), 0em 2.5em 0 0em rgba(0,47,58, 0.2), -1.8em 1.8em 0 0em rgba(0,47,58, 0.5), -2.6em 0em 0 0em rgba(0,47,58, 0.7), -1.8em -1.8em 0 0em map-get($colours, 'primary');
	}
}

.loader {
	$block: #{&};

	width: 4rem;
	height: 4rem;
	// position: relative;

	opacity: 0;
	visibility: hidden;

	transition: all 0.3s ease-in-out;

	&--full-screen {
		background: map-get($colours, 'overlay');
		display: none;

		width: auto;
		height: auto;

		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		z-index: 100;
	}

	&--active {
		display: block;
		opacity: 1;
		visibility: visible;
	}

	&__spinner {
		width: 6px;
		height: 6px;
		overflow: hidden;

		position: absolute;
		top: 50%;
		left: 50%;
		margin: -3px 0 0 -3px;

		color: map-get($colours, 'primary');
		font-size: 4px;
		text-indent: -9999em;

		border-radius: 50%;
		transform: translateZ(0);
		animation: load5 1.1s infinite ease;

		#{$block}--full-screen & {
			width: 1em;
			height: 1em;

			margin: -.5em -.5em 0 0;

			font-size: 25px;
		}
	}	
}

.loader-medium {
	$block: #{&};

	width: 4rem;
	height: 4rem;
	position: relative;

	opacity: 0;
	visibility: hidden;

	transition: all 0.3s ease-in-out;

	&--active {
		display: block;
		opacity: 1;
		visibility: visible;
	}

	margin: 100px auto;
	position: relative;

	color: map-get($colours, 'primary');
	font-size: 35px;
	text-indent: -9999em;
	
	border-radius: 50%;
	transform: translateZ(0);
	animation: load5 1.1s infinite ease;

  }