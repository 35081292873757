/**
 * PaymentMethod
 */

.payment-method {
	$block: #{&};

	&__section {
		padding-left: 0;
		padding-right: 0;
	}

	&__paymentSection {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 40rem;
		overflow: hidden;
		padding-bottom: 2rem;
	}
}
