@import '../../../scss/base/variables';

/**
 * Services
 */

@keyframes servicesFadeOutIn {
	50% {
		opacity: 0;
	}
}

@keyframes servicesFadeOutIn2 {
	50% {
		opacity: 0;
	}
}

.services {
	$block: #{&};

	margin: 4rem 0 0;
	padding: 0;
	list-style-type: none;

	display: flex;
	justify-content: center;//space-between
	flex-wrap: wrap;
	position: relative;

	grid-template-columns: repeat(auto-fit, 40rem);
	grid-gap: 20px;

	&--animate {
		animation: servicesFadeOutIn2 600ms ease-in-out forwards;
	}

	&--selected {
		animation: servicesFadeOutIn 600ms ease-in-out forwards;
		opacity: 1;
	}

	&__catalogue {
		background: map-get($colours, 'ocean');
		width: 100%;
		//min-height: 35rem;
		//margin-bottom: 2rem;

		box-shadow: $defaultShadow;
		position: relative;

		&--hide {
			display: none;
		}

		&__headline {
			color: map-get($colours, 'white');
			font-size: 2.6rem;
			line-height: 3rem;
			text-align: center;
			font-weight: 700;

			max-width: 26.4rem;
			min-height: 12rem;//6rem
			margin: 0 auto 2rem;
			padding: 0 1rem 2rem;

			border-bottom: .1rem solid map-get($colours, 'primary-60');

			#{$block}__catalogue--action & {
				color: map-get($colours, 'white');
			}

			#{$block}__catalogue--expanded & {
				max-width: none;
				margin-top: 1rem;
				padding-left: 2rem;
				padding-right: 2rem;
				min-height: 3rem;
			}
		}
	}

&__catalogue {
		background: map-get($colours, 'ocean');
		width: 100%;
		//min-height: 35rem;
		//margin-bottom: 2rem;

		box-shadow: $defaultShadow;
		position: relative;

		&--hide {
			display: none;
		}

		&--expanded {
			margin: 0 10px 0 10px;
		}

		&__headline {
			color: map-get($colours, 'white');
			font-size: 2.6rem;
			line-height: 3rem;
			text-align: center;
			font-weight: 700;

			max-width: 26.4rem;
			min-height: 12rem;//6rem
			margin: 0 auto 2rem;
			padding: 0 1rem 2rem;

			border-bottom: .1rem solid map-get($colours, 'primary-60');

			#{$block}__catalogue--action & {
				color: map-get($colours, 'white');
			}

			#{$block}__catalogue--expanded & {
				max-width: none;
				margin-top: 1rem;
				padding-left: 2rem;
				padding-right: 2rem;
				min-height: 3rem;
			}
		}
	}

	&__button {
		width: 100%;
		margin-top: auto;
		//padding-top: 2rem;
		padding: 4px;
	}

	&__container {
		color: map-get($colours, 'white');
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: hidden;

		font-size: 2rem;
		line-height: 3rem;
		font-weight: 500;

		padding: 2.5rem;
		position: relative;
	}

	&__wrapper {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__info-icon {
		position: absolute;
		top: 2rem;
		right: 1.5rem;

		border-color: map-get($colours, 'white');
		min-height: 0;

		cursor: pointer;

		&:after {
			color: map-get($colours, 'white');
		}
	}

	&__headline {
		.service-table + & {
			margin-top: 6rem;
		}
	}

	&__back-button {
		position: absolute;
		top: 1rem;
		left: 1.5rem;

		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		z-index: -1;

		transition: none;
		box-shadow: none;

		#{$block}__catalogue--expanded & {
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
			z-index: 1;
		}
	}

	&__content {
		max-width: 35rem;
		text-align: left;
		margin: 10px 0 10px 0;
		color: map-get($colours, 'white');

		&--pack {
			height: 100%;
		}
	}

	&__service {
		display: flex;
		flex-direction: column;
		align-items: center;
		background: map-get($colours, 'ocean');
		// padding-top: 1rem;
		
		& + & {
			margin-top: 4rem;
			padding-top: 3rem;
			border-top: 1px solid map-get($colours, 'primary-60');
		}

		&:last-child {
			padding-bottom: 2rem;
		}

		&:only-child {
			height: 100%;
			padding-bottom: 0;
		}

		&__headline {
			margin-top: 10px;
			color: map-get($colours, 'white');
			text-align: center;
		}

		&__description {
			margin: 10px;
		}
	}

	&__promo-form {
		margin-bottom: 4rem;
	}

	@include breakpoint($tablet) {

		display: grid;

		&__catalogue {
			min-width: calc(#{percentage(1/3)} - 10px);
			width: calc(#{percentage(1/3)} - 10px);

			margin-bottom: 0;

			opacity: 1;
			visibility: visible;
			pointer-events: auto;

			&--expanded[class] {
				width: 100%;
			}
		}

		&__content {

			&--pack {
				height: 100%;
				width: 100%;
				min-width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-wrap: wrap;
			}
		}

		&__service {
			width: calc(#{percentage(1/3)} - 2rem);
			height: 21rem;//20rem

			&[class] {
				margin: 0 1rem;
				padding: 0;
				border: none;
				//height: auto;
			}

			/* Every item after the 3rd */
			&:nth-child(n+4) {
				margin-top: 4rem;
			}

			/* Only one or two items */
			&:only-child,
			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ & {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&__back-button {
			left: 2.5rem;
			top: 2.5rem;
		}
	}
}
