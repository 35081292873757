@import '../../../scss/base/variables';

/**
 * Footer
 */

.footer {
    width: 100%;
    display: flex;
    padding-top: 8rem;

    background: map-get($colours, 'background');
    color: map-get($colours, 'copy');

    &__link {
        color: map-get($colours, 'copy');
        border-bottom: 1px solid map-get($colours, 'light-grey');
    }

    &__copyright {
        width: 100%;
        float: none;

        text-align: center;
        font-size: 1.6rem;
        line-height: 1.8rem;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    p {
        width: 100%;
    }

    &__list {
        list-style-type: none;
        margin: 0 0 4rem;
        padding: 0;

        &__item {

            & + & {
                margin-top: 1rem;
            }
        }
    }

    @include breakpoint($tabletLarge) {

        &__copyright {
            height: 100%;
        }
    }

    @include breakpoint($desktop) {

        &__list {

            &__item {
                margin-right: 1rem;
                display: inline;

                &[class] {
                    font-size: 1.6rem;
                }

                & + & {
                    border-left: 1px solid map-get($colours, 'light-grey');
                    padding-left: 1rem;
                    margin-top: 0;
                }
            }
        }
    }
}
