@import 'breakpoint-sass/stylesheets/breakpoint';

/**
 * Variables
 */

$colours: (
		'content': #FFF,
		//'background': #F7F5F4,
		'background': #FFF,
		'overlay': rgba(0, 0, 0, 0.2),
		'overlay-dark': rgba(0, 0, 0, 0.2),
		//'overlay': rgba(255, 255, 255, 0.7),

		'black': #000000,
		'primary': #002E3A,
		'primary-60': mix(#002E3A, white, 60%),
		'grey': #8EAFBF,
		'light-grey': #BFBFBF,
		'lighter-grey': #F7F5F4,
		'disabled': #DDD,

		'ocean': #1052a2,
		'sky': #00A8FC,

		'green': #00FF73,
		'orange': #E57E30,
		'red': #C23250,

		'link': rgb(16, 82, 162),
		'copy': #264c55,
//		'link': rgb(15, 120, 195),
//		'copy': #242121,
		'white': #FFF,

		'spotlite-background': #F7F5F4
);

/*
$colours: (
	'content': #FFF,
	'background': #FFF,
	'overlay-dark': rgba(0, 0, 0, 0.2),
	'overlay': rgba(255, 255, 255, 0.7),

	'primary': #1052a2,
	'light-grey': #BFBFBF,
	'lighter-grey': #F7F5F4,

	'link': rgb(16, 82, 162),
	'copy': #264c55,
	'white': #FFF,
	'sky': #00A8FC,

	'spotlite-background': #F7F5F4
);
*/

$timings: (
	'default': 0.3s,
	'fast': 0.2s
);

$transitions: (
		'hide': (
				z-index map-get($timings, 'default') step-end,
				visibility map-get($timings, 'default') step-end,
				opacity map-get($timings, 'default') ease-in-out
		),
		'show': (
				z-index map-get($timings, 'default') step-start,
				visibility map-get($timings, 'default') step-start,
				opacity map-get($timings, 'default') ease-in-out
		)
);

$defaultShadow: 0px 2px 0px 0px rgba(0,0,0,0.05);

$mobileLarge: 470px, 'no-query' true;
$tabletSmall: 600px, 'no-query' true;
$tablet: 768px, 'no-query' true;
$tabletLarge: 935px, 'no-query' true;
$desktop: 1024px, 'no-query' true;
$desktopWide: 1400px, 'no-query' true;

// Convert breakpoints to ems
@include breakpoint-set('to ems', true);
