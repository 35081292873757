@import '../../../scss/base/variables';

/**
 * Search Results
 */

.search-results {
	$block: #{&};

	list-style: none;
	margin: 0;
	padding: 0;

	text-align: left;

	&__result {

		& + & {
			margin-top: 1rem;
			padding-top: 1rem;
			border-top: 1px solid map-get($colours, 'background');
		}
	}

	&__link {
		cursor: pointer;
	}
}
