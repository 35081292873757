@import '../../../scss/base/variables';

/**
 * Service Table
 */

.service-table {
	$block: #{&};

	width: 100%;
	border-collapse: collapse;

	tr {
		display: block;
	}

	thead {
		display: none;
	}

	thead tr {
		border-bottom: .1rem solid map-get($colours, 'light-grey');
	}

	tbody tr + tr {
		border-top: .1rem solid map-get($colours, 'light-grey');
	}

	td {
		text-align: left;
		padding: 1rem;

		&:first-child {
			padding-top: 2rem;
		}

		&:last-child {
			padding-bottom: 2rem;
		}	
	}

	&__total {
		border-top: .1rem solid map-get($colours, 'blue');
	}

	&__column {
		&--mobile-break[class] {
			display: block;
			width: 100%;
			text-align: center;

			td + & {
				padding-top: 0;
			}
		}

		&--small {
			small {
				display: block;
				font-size: 1.4rem;
			}
		}
	}

	&__title {
		color: map-get($colours, 'primary');
		display: block;
		font-weight: 500;
	}

	&__description {
		display: block;
		font-size: 1.6rem;
	}

	&__cost {
		color: map-get($colours, 'primary');
		font-size: 2.4rem;
		line-height: 2.6rem;
		font-weight: 700;
		display: block;

		&--summary {
			font-weight: 500;
		}
	}

	&__carry_over_cost {
		color: map-get($colours, 'primary');
		font-size: 2.4rem;
		line-height: 2.6rem;
		font-weight: 500;
		display: block;

		&--summary {
			font-weight: 500;
		}
	}

	&__right {
		text-align: right;
		display: block;
	}

	&__unit {
		display: block;
		font-size: 1.5rem;
	}

	@include breakpoint($tablet) {

		thead {
			display: table-header-group;
		}

		tr {
			display: table-row;
		}

		td,
		td:first-child,
		td:last-child {
			padding: 2rem 1rem;
		}

		&__mobile-only {
			display: none;
		}

		&__column {
			&--mobile-break[class] {
				width: auto;
				display: table-cell;
				text-align: left;

				td + & {
					padding-top: 2rem;
				}
			}

			&--small[class] {
				width: 10rem;
				min-width: 10rem;
				//max-width: 10rem;
				text-align: center;

				thead & {
					vertical-align: top;
				}
			}

			&--medium[class] {
				width: 20rem;
				min-width: 20rem;
				max-width: 20rem;
			}

			&--right[class] {
				text-align: right;
			}
		}
	}
}
