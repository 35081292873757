@import '../../../scss/base/variables';

/**
 * AutoModal
 */

.automodal {
	$block: #{&};

	&__text {
		margin-left: 0.5rem;
		color: map-get($colours, 'link');
		cursor: pointer;

		&:hover,
		&:focus {
			color: map-get($colours, 'copy');
		}
	}
}
